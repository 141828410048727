export const CONSTANTS = {
  NOT_FOUND: 'Not Found',
  YES: 'Yes',
  NO: 'No',
  ENDPOINTS: {
    MIA: {
      FACILITY: '/facilities',
      INSPECTIONS: '/inspections',
      ORDS_VALIDATION: '/ordsvalidation',
      IMAGES: '/images',
      WORK_REQUESTS: '/workrequests',
      CREW_CODE: '/crewcodes',
      OPEN_CONDITIONS: '/open-conditions',
      CASE: '/case',
      CASE_DETAILS: '/case/details',
    },
    USERS: '/users',
    MECHANIC: '/mechanic',
    SUPERVISOR: '/supervisor',
    MEDIA: '/media',
    IMAGES: '/images',
    LOCATION_DATA: '/locationdata',
    QUESTIONS: '/questions',
    STRUCTURAL_DEFECTS: '/structural-defects',
    SEARCH: '/search',
    REPORTS: '/reports',
    FILTER_OPTIONS: '/filter-options',
  },
  USER_ROLES: {
    MECHANIC: 'Mechanic',
    SUPERVISOR: 'Supervisor',
  },
  PORTAL_ACCESS: {
    CUSTOMIZED: 'Customized',
    GENERAL: 'General',
  },
  JOB_STATUS: {
    IN_PROGRESS: 'In-Progress',
    SCHEDULED: 'Scheduled',
    PREVIOUS_SHIFT: 'Previous Shift',
    COMPLETED: 'Completed',
  },
  DEFECT_STATUSES: {
    DEFECT_IDENTIFIED_SUPERVISOR: 'Defect Identified_Supervisor',
    DEFECT_IDENTIFIED_MECHANIC: 'Defect Identified_Mechanic',
    DEFECT_CANCELLED: 'Defect Cancelled',
    DEFECT_IDENTIFIED_GENERAL: 'Defect Identified_General',
    DE_CIVIL_APPROVED: 'DE Civil Approved',
    DE_CIVIL_CLOSED: 'DE Civil Closed',
    DE_CIVIL_DOWNGRADED: 'DE Civil Downgraded',
    HISTORY: 'History',
  },
  STRUCTURE_IMAGE_STATUS: {
    IN_PROGRESS: 'In-Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  COLORS: {
    GREEN: '#00882B',
    BLUE: '#0078CF',
    ORANGE: '#FF8E2C',
  },
  ROUTES: {
    FIRST_TIME_SCREEN: 'first-time',
    MAIN_DASHBOARD: 'main-dashboard',
    GENERAL: 'general',
    LOGIN: 'login',
    CONTACT_US: 'contact-us',
    SUPERVISOR_JOBS: 'supervisor-jobs',
    MECHANIC_JOBS: 'mechanic-jobs',
    JOB_DETAILS: 'details',
    WORK_REQUESTS: 'work-requests',
    CREWS: 'crews',
    STRUCTURAL_DEFECTS: 'structural-defects',
    NEW_DEFECT: 'new',
    DEFECT_DETAILS: 'details',
    DEFECT_DETAILS_REVIEW: 'review',
    DEFECT_DETAILS_SUMMARY: 'summary',
    DEFECT_GALLERY: 'gallery',
    SEARCH: 'search',
    SEARCH_DEFECT: 'defect',
    GLOBAL_ERROR: 'global-error',
    MIA: {
      MIA: 'mia',
      FOD: 'fod',
      NEW_INSPECTION: 'new-inspection',
      CREWS: 'crews',
      STRUCTURE_VALIDATION: '/mia/fod/structure-validation',
      STRUCTURE_CONFIRMATION: '/mia/fod/structure-confirmation',
      FOD_IMAGE_UPLOAD: {
        VIEW: 'mia/fod/image-upload/view',
        EDIT: 'mia/fod/image-upload/edit',
        DETAILS: 'mia/fod/image-upload/details/__inspectionId__/__imageId__',
      },
      FOD_SUMMARY: '/mia/fod/summary',
      SIP_IMAGE_UPLOAD: {
        VIEW: 'mia/sip/image-upload/view',
        EDIT: 'mia/sip/image-upload/edit',
      },
      SIP: {
        LANDING: 'mia/sip/landing',
        INSPECTION: 'mia/sip/new-inspection',
        HOTSPOT_STATUS: 'mia/sip/hotspot-status',
        REASON_UNABLE_THERMAL: 'mia/sip/reason-unable-thermal',
        PRE_INSPECT: 'mia/sip/pre-inspect',
        STRAY_VOLTAGE: 'mia/sip/stray-voltage',
        PROCEDURE_QUESTIONS: '/procedurequestions',
        PROCEDURE_RESULT: '/procedureresults',
        GROUP_TWO: 'mia/sip/group-two',
        SUMP_PUMP_INSPECTION: 'mia/sip/sump-pump-inspection',
        SIP_SUMMARY: '/mia/sip/summary',
        STRUCTURE_VALIDATION: '/mia/sip/structure-validation',
        ASSET_SELECTION: '/mia/sip/asset-selection',
      },
      DG: {
        NEW_INSPECTION: 'mia/dg/new-inspection',
        MC_INSPECTION_INFORMATION: 'mia/dg/mc-inspection-information',
        DG_IMAGE_UPLOAD: 'mia/dg/image-upload/view',
        DG_SUMMARY: '/mia/dg/summary',
      },
    },
  },
  VALIDATORS: {
    REQUIRED: 'Required',
    MIN_LENGTH: 'minLength',
    MAX_LENGTH: 'maxLength',
  },
  ERROR_MESSAGES: {
    DEFECT_ALREADY_EXISTS:
      'This Asset already has a structural defect under review. Consult with your Supervisor if this appears to be a mistake.',
  },
  EXTERNAL_URLS: {
    GOOGLE_MAPS_DESTINATION: 'https://www.google.com/maps?daddr=',
    GLOBAL_ERROR_CREATE_TICKET: 'https://forms.office.com/r/reyK2n2Wkg',
    CONTACT_US_FORM:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Contact-Us.aspx',
    TUTORIALS:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Tutorials.aspx',
    TROUBLESHOOTING_GUIDE:
      'https://consolidatededison.sharepoint.com/sites/CentralConnect/SitePages/Troubleshooting-Guide.aspx',
    TERMS_AND_CONDITIONS: 'https://www.coned.com/en/terms-and-conditions',
    SPILL_REPORT_GUIDE:
      'https://consolidatededison.sharepoint.com/sites/ehs-cecony/PoliciesAndProcedures/Forms/AllItems.aspx?id=%2Fsites%2Fehs%2Dcecony%2FPoliciesAndProcedures%2FGEHSI%20E02%2E01%20%2D%20Spill%20Reporting%2Epdf&parent=%2Fsites%2Fehs%2Dcecony%2FPoliciesAndProcedures',
  },
  TELEMETRY: {
    FILENAME: {
      PORTAL_GUARD: 'Portal Access Guard',
      APP_COMPONENT: 'App/Root Component',
    },
  },
  EVENT_NAME: {
    SESSION_EXPIRED: 'Session Expired',
    PORTAL_GUARD: 'Portal Access Guard',
    CREATE_TICKET: 'Create Defect',
    EDIT_TICKET: 'Edit/View Defect',
    VIEW_TICKET: 'View Defect',
    SAVE_TICKET: 'Save Defect',
    SUBMIT_TICKET: 'Submit Defect',
    CANCEL_TICKET: 'Cancel Defect',
    // DELETE_TICKET: 'Delete Ticket',
    // ASSIGN_MECHANIC: 'Assign Mechanic',
    // SELECT_WORK_TYPE: 'Select Work Type',
    // DOWNLOAD_PDF: 'Download PDF',
    // VIEW_PDF: 'View PDF',
    // LOCATION_CHANGE:'Location Change',
    // WR_NUMBER: 'Work Request Number',
    // MANUALLY_ENTER_STRUCTURE: 'Manually Enter Structure',
    // VALIDATE_STRUCTURE: 'Validate Structure',
    // INSPECTION_INFORMATION: 'Inspection Information',
    GET_STARTED: 'Get Started',
    SEARCH: 'Search',
    ASSET: 'Asset',
    VALIDATE: 'Validate',
    NEXT: 'Next',
    RESET: 'Reset',
    CHANGE: 'Selection Change',
    PREVIOUS: 'Previous',
    ON_LOCATION: 'On Location',
    // SKIP_WR: 'Skip WR',
    NAVIGATE: 'Navigate',
    API_FAILURE: 'API Failure',
    API_CALL: 'API Call',
    FETCH_DATA: 'Fetch data from backend',
    SUBMIT: 'Submit',
    YES: 'Yes',
    SHOW_DIRECT_REPORTS: 'Show direct reports',
    // SHOW_IN_PROGRESS_CONFIRMATION: 'In-Progress confirmation',
    APPLY_FILTERS: 'Apply filters',
    // DOWNLOAD_IMAGES: 'Download Images',
    DELETE_IMAGE: 'Delete Image',
    SAVE_IMAGE: 'Save Image',
    UPDATE_IMAGE: 'Update Image',
    IMAGE_ACTION: 'Image Actions',
    VIEW_IMAGE: 'View Image',
    IMAGE_SELECTED: 'Image Selected',
    IMAGE_UPLOAD: 'Image Upload',
    CANCEL_UPLOAD: 'Cancel Upload',
    OPEN_CAMERA: 'Open Camera',
    CLOSE_CAMERA: 'Close Camera',
    CAPTURE_IMAGE: 'Capture Image',
    DISPLAY_MEDIA: 'Display Media',
    // SAVE_FOR_LATER: 'Safe for later',
    VIEW_SUMMARY: 'View Summary',
    SHOW_CLOSE_MODAL: 'Show/Close modal',
    LOG_IN: 'Log in',
    LOG_OUT: 'Log out',
    INSTALL_PWA: 'Install PWA',
    OPT_OUT_INSTALL_PWA: 'Install PWA Opt Out',
    AUTHENTICATION_SUCCESS: 'User Authentication Successful',
    // ADD_JOINT: 'Add Joint',
    // EDIT_JOINT: 'Edit Joint',
    // DELETE_JOINT: 'Delete Joint',
    // SAVE_JOINT: 'Save Edited Joint',
    // CLEAR_JOIN: 'Clear Joint Form',
    // VERIFY: 'Verify',
    // REVISION_BY_MECHANIC: 'Revision by Mechanic',
    ALERT: 'Alert',
    // WR_NOT_FOUND: 'Work Request Number Invalid',
    // REFRESH_DASHBOARD: 'Refresh Dashboard',
    BEGIN_TICKET: 'Begin Ticket',
    GOOGLE_MAPS: 'Google Maps',
    // RECALL: 'Recall',
    // REVISION_COMPLETE: 'Revision Complete',
    // DELETE_VIDEO: 'Delete Video',
    // GENERATE_PDF_FAILURE: ' Generate PDF Failure',
    SERVICE_WORKER_UPDATE: 'Service Worker Update',
    EDIT_IMAGE: 'Edit Image',
    EXCEPTION: 'Exception',
    UPLOAD_FILE: 'Upload File',
    INTERCEPTOR: 'Interceptor',
    // UPLOAD_VIDEO: 'Upload Video',
    SUBMIT_INSPECTION: 'Submit Inspection',
  },
  QUESTION_TYPES: {
    DATEPICKER: 'Datepicker',
  },
  MIA: {
    WORK_TYPE: {
      UG_SIP: 'UG SIP',
      FOD: 'FOD',
      OTHER: 'OTHER',
      ALL: 'ALL',
      ERA: 'ERA',
      DG: 'DG',
    },
    INSPECTION_STATUS: {
      IN_PROGRESS: 'In-Progress',
      COMPLETED: 'Completed',
      CONTRACTOR: 'Contractor',
      VIDEO_UPLOAD_FAILED: 'Video Upload Failed',
      DELETED: 'Deleted',
    },
    PHOTOTYPES: [
      {KEY: 'Thermal', VALUE: 'IR'},
      {KEY: 'Inspection', VALUE: 'Insp'},
      // { KEY: 'Switching', VALUE: 'SW' }
    ],
    PHOTO_IDENTIFICATION: [
      {KEY: 'Hot Spot Found', VALUE: 'Hot Spot Found'},
      {KEY: 'Hot Spot Not Found', VALUE: 'Hot Spot Not Found'},
    ],
    FOD_PHOTO_TYPES: [
      {KEY: 'Grounds', VALUE: 'Grounds'},
      {KEY: 'ID', VALUE: 'ID'},
      {KEY: 'Switching', VALUE: 'Switching'},
    ],
    DG_EQUIPMENT_TYPES: [
      {KEY: 'Utility meter', VALUE: 'UtilityMeter'},
      {KEY: 'DG meter', VALUE: 'DGMeter'},
      {KEY: 'Utility disconnect', VALUE: 'UtilityDisconnect'},
      {KEY: 'Generator disconnect', VALUE: 'GeneratorDisconnect'},
      {KEY: 'Generator/turbine', VALUE: 'Generator'},
      {KEY: 'PV module', VALUE: 'PVModule'},
      {KEY: 'Battery module/container', VALUE: 'BatteryModule'},
      {KEY: 'Inverter', VALUE: 'Inverter'},
      {KEY: 'Other', VALUE: 'Other'},
    ],
    PHOTO_VIEW_POINT: [
      {KEY: 'East Wall', VALUE: 'East Wall'},
      {KEY: 'West Wall', VALUE: 'West Wall'},
      {KEY: 'North Wall', VALUE: 'North Wall'},
      {KEY: 'South Wall', VALUE: 'South Wall'},
      {KEY: 'Floor', VALUE: 'Floor'},
      {KEY: 'Other View', VALUE: 'Other View'},
    ],
    PSC_UG_FORM_OPTIONS: {
      IR_SCAN: {
        IR_FOUND_NOT_REPAIRED: 'IR hot spot found and not repaired',
        IR_NOT_FOUND_REPAIRED: 'IR hot spot found and repaired',
        NO_IR_FOUND: 'No IR hot spot found ',
        UNABLE_TO_TAKE: 'Unable to take thermal image',
      },
      REASONS_UNABLE_THERMAL: [
        'Unable to connect thermal camera',
        'Do not have a thermal camera',
        'Other',
      ],
    },
    FOD_SUMMARY: {
      MAIN_DASHBOARD: ['/main-dashboard/supervisor-jobs/work-requests'],
      IMAGE_UPLOAD: ['mia/fod/image-upload/view'],
    },
    SIP_SUMMARY: {
      PRE_INSPECTION: ['mia/sip/pre-inspect'],
      STRAY_VOLTAGE: ['mia/sip/stray-voltage'],
      IMAGE_UPLOAD: ['mia/sip/image-upload/view'],
      GROUP_TWO: ['mia/sip/group-two'],
      SUMP_PUMP: ['mia/sip/sump-pump-inspection'],
      SIP_SUMMARY: ['mia/sip/summary'],
      MAIN_DASHBOARD: ['/main-dashboard/supervisor-jobs/work-requests'],
    },
    INSPECTION_TYPE: {
      SCHEDULED: 'SCHEDULED',
      ADHOC: 'ADHOC',
    },
    IMAGES_SECTION: {
      STRAY_VOLTAGE: 'STRAY_VOLTAGE',
      CREW_CONDUCTING_REPAIRS: 'CREW_CONDUCTING_REPAIRS',
      UNABLE_TO_COMPLETE: 'UNABLE_TO_COMPLETE',
      ACCESS_RESTRICTION: 'ACCESS_RESTRICTION',
      DEFECTS_IDENTIFIED: 'DEFECTS_IDENTIFIED',
    },
    SUMP_PUMP_STRUCTURES: ['BUSCOMP', 'BUSCOMPIB', 'VAULT', 'VAULTIB'],
  },
  TEST: 'test 2',
  MESSAGE: {
    WR_NUMBER_ERROR_MESSAGE:
      "This WR Number is incorrect, please make sure you're typing it correctly.",
    FEEDER_NUMBER_ERROR_MESSAGE:
      "This feeder number is incorrect, please make sure you're typing it correctly.",
    MC_NUMBER_ERROR_MESSAGE:
      "This MC Number is incorrect, please make sure you're typing it correctly.",
  },
  IR_CAMERA: {
    FLIR: 'FLIR',
    FLUKE: 'FLUKE',
  },
  STOPPED_CONDITION_PSCU: 'PSCSU',
};
