import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs';
import {MiaImageService} from 'src/app/shared/services/mia-image/mia-image.service';
import {
  DeleteImages,
  ResetInspectionAppState,
  SaveImages,
  SaveInspection,
  SaveWRDetails,
} from './inspection.actions';
import {IInspectionState} from './inspection.model';

@State<IInspectionState>({
  name: 'InspectionState',
  defaults: {
    inspections: [],
    inspection: null,
    images: [],
    WrDetails: null,
  },
})
@Injectable()
export class InspectionState {
  constructor(private _miaImageService: MiaImageService) {}

  @Selector()
  static getInspectionDetails(state: IInspectionState) {
    return state?.inspection;
  }

  @Selector()
  static getInspectionImages(state: IInspectionState) {
    return state?.images;
  }

  @Selector()
  static getWrDetails(state: IInspectionState) {
    return state?.WrDetails;
  }

  @Action(ResetInspectionAppState)
  ResetAppState(ctx: StateContext<IInspectionState>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      inspection: null,
      inspections: [],
    });
  }

  @Action(SaveInspection)
  SaveInspection(ctx: StateContext<IInspectionState>, action: SaveInspection) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      inspection: action.inspection,
    });
  }

  @Action(SaveImages)
  SaveImages(ctx: StateContext<IInspectionState>, action: SaveImages) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      images: action.images,
    });
  }

  @Action(DeleteImages)
  DeleteImages(ctx: StateContext<IInspectionState>, action: DeleteImages) {
    return this._miaImageService.deleteImages(action.imageIds).pipe(
      tap((response: any) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          images:
            action.imageIds.length > 1
              ? []
              : state.images.filter((img) => img.id !== action.imageIds[0]),
        });
      })
    );
  }

  @Action(SaveWRDetails)
  SaveWRDetails(ctx: StateContext<IInspectionState>, action: SaveWRDetails) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      WrDetails: action.wr,
    });
  }
}
